import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactSection: React.FC = () => {
  const { t } = useTranslation('translation');
  const listContact = [
    {
      id: 1,
      title: t('ctc-title1'),
      description: t('ctc-card1'),
      iconName: 'fa-map-marker-alt',
    },
    {
      id: 2,
      title: t('ctc-title2'),
      description: t('ctc-card2'),
      iconName: 'fa-phone',
    },
    {
      id: 3,
      title: t('ctc-title3'),
      description: t('ctc-card3'),
      iconName: 'fa-envelope',
    },
  ];
  return (
    <div className=" w-full pb-[3rem] pt-[4rem] md:pt-[8rem]">
      <h2 className="heading">{t('t-contact')}</h2>
      <div className="grid border-b-[1px] pb-[6rem] border-gray-400 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 w-[80%] mx-auto gap-[3rem]">
        {listContact.map((i) => (
          <div key={i.id} className="flex items-center space-x-6">
            <div className="md:w-[4rem] md:h-[4rem] w-[3rem] h-[3rem] flex items-center justify-center rounded-full bg-[#E7FFEF]">
              <i className={`fas ${i.iconName} fa-lg`}></i>
            </div>
            <div className="text-center  w-full h-auto ">
              <h2 className="text-[15px] mb-[0.2rem] font-semibold text-[#108236] ">
                {i.title}
              </h2>
              <p className="text-[13px] w-full text-black ">{i.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <p className="text-lg sm:text-xl text-black w-4/5 italic text-center relative before:content-['-'] after:content-['-'] before:text-3xl after:text-3xl">
          {t('join')}
        </p>
      </div>
    </div>
  );
};

export default ContactSection;

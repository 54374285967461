import React, { ReactNode, RefObject } from 'react';
import APPCOLORS from 'constant/color';
import logoImg from 'asset/logoV1.png';
import { useTranslation } from 'react-i18next';
import LanguageSelector from 'components/languageSelector';

interface CustomCSSProperties extends React.CSSProperties {
  '--hover-color'?: string; // Add custom CSS variable here
}

interface MenuProps {
  children?: ReactNode;
  navRef?: RefObject<HTMLUListElement>;
  nav?: boolean;
  closeNav?: () => void;
}

const MenuComponent: React.FC<MenuProps> = ({ nav, navRef, closeNav }) => {
  return (
    <>
      <DesktopMenu>
        <Menu closeNav={closeNav} />
      </DesktopMenu>
      <MobileMenu nav={nav} navRef={navRef}>
        <Menu closeNav={closeNav} />
      </MobileMenu>
    </>
  );
};

const Menu: React.FC<MenuProps> = ({ closeNav }) => {
  const { t } = useTranslation('translation');
  const menuItems = [
    { id: 1, text: t('contexte'), href: '#contexte' },
    { id: 2, text: t('about'), href: '#about' },
    { id: 3, text: t('service'), href: '#services' },
    { id: 4, text: t('impact'), href: '#impact' },
    { id: 5, text: t('projet'), href: '#projets' },
    { id: 6, text: t('contact'), href: '#contact' },
  ];

  return (
    <>
      <div className="p-4 m-2" onChange={closeNav}>
        <LanguageSelector />
      </div>
      {menuItems.map((item) => (
        <li
          key={item.id}
          style={{ '--hover-color': APPCOLORS.primary } as CustomCSSProperties}
          className="hover:bg-[var(--hover-color)] p-4 rounded-xl m-2 cursor-pointer duration-300 text-black font-bold"
          onClick={closeNav}
        >
          <a href={item.href}>{item.text}</a>
        </li>
      ))}
    </>
  );
};

const DesktopMenu: React.FC<MenuProps> = ({ children }) => {
  return (
    <ul className="hidden md:flex items-center justify-end space-x-4">
      {children}
    </ul>
  );
};

const MobileMenu: React.FC<MenuProps> = ({ children, navRef, nav }) => {
  return (
    <ul
      ref={navRef}
      style={{ backgroundColor: APPCOLORS.green1 }}
      className={
        nav
          ? 'fixed md:hidden left-0 top-0 w-[50%] h-full border-r  ease-in-out duration-500'
          : 'ease-in-out w-[50%] duration-500 fixed top-0 bottom-0 left-[-100%]'
      }
    >
      {/* Mobile Logo */}
      <img
        src={logoImg}
        className="h-20 w-auto object-contain m-2 "
        alt="Betaclic"
        width="80" /* Exemple de largeur */
        height="40"
        title="Betaclic"
        loading="eager"
      />

      {children}
    </ul>
  );
};
export default MenuComponent;

import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation('translation');
  const listServices = [
    {
      id: 1,
      title: t('sect-title1'),
      description: t('sect-card1'),
      iconName: 'fa-cow',
    },
    {
      id: 2,
      title: t('sect-title2'),
      description: t('sect-card2'),
      iconName: 'fa-map-marker-alt',
    },
    {
      id: 3,
      title: t('sect-title3'),
      description: t('sect-card3'),
      iconName: 'fa-stethoscope',
    },
    {
      id: 4,
      title: t('sect-title4'),
      description: t('sect-card4'),
      iconName: 'fa-chalkboard-teacher',
    },
    {
      id: 5,
      title: t('sect-title5'),
      description: t('sect-card5'),
      iconName: 'fa-road',
    },
    {
      id: 6,
      title: t('sect-title6'),
      description: t('sect-card6'),
      iconName: 'fa-lightbulb',
    },
  ];
  return (
    <div className="pt-[4rem] md:pt-[8rem] pb-[5rem]">
      <h2 className="heading">{t('t-service')}</h2>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-[80%] mx-auto items-center gap-[3rem] mt-[4rem] text-white"> */}
      <div className="grid grid-cols-1 md:grid-cols-2  w-[80%] mx-auto items-center gap-[3rem] mt-[4rem] text-white">
        {listServices.map((i) => (
          <div
            key={i.id}
            data-aos="zoom-in"
            data-aos-delay="300"
            className="flex justify-center text-center"
          >
            <div className="bg-[#108236] hover:scale-110 transform transition-all duration-300    text-center p-[2rem] w-full h-[500px] md:h-[400px]">
              {/* <i className={`fab ${i.iconName} fa-lg`}></i> */}
              <div className="absolutes top-3 right-1 md:top-4 md:right-2 w-[3rem] h-[3rem] md:w-[4rem] md:h-[4rem] flex items-center justify-center rounded-full bg-black">
                <i className={`fas ${i.iconName} fa-lg`}></i>
              </div>
              <h2 className="text-lg sm:text-xl mt-[1.5rem] uppercase  font-semibold">
                {i.title}
              </h2>
              <p className="text-lg sm:text-xl  text-[#d3d2d2] font-normal">
                {i.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceSection;

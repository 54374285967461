import React from 'react';
import Navbar from 'components/navbar';
import Banner from 'components/Banner';
import Section from 'components/Section';
import Footer from 'components/Footer';
import ContextSection from 'components/contextSection';
import AboutSection from 'components/aboutSection';
import ServiceSection from 'components/serviceSection';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ImpactSection from 'components/impactSection';
import ProjetSection from 'components/projetSection';
import ContactSection from 'components/contactSection';

const App: React.FC = () => {
  return (
    <div className="font-sans text-gray-800">
      <Navbar />
      <Banner />
      <main className="pt-[80px] mb-10 md:mb-20 lg:mb-24">
        <Section id="contexte">
          <ContextSection />
        </Section>
        <Section id="about">
          <AboutSection />
        </Section>
        <Section id="services">
          <ServiceSection />
        </Section>
        <Section id="impact">
          <ImpactSection />
        </Section>
        <Section id="projets">
          <ProjetSection />
        </Section>
        <Section id="contact">
          <ContactSection />
        </Section>
      </main>
      <Footer />
    </div>
  );
};

export default App;

import navbar from './navbar.json';
import banner from './banner.json';
import title from './title.json';
import contexte from './contexte.json';
import about from './about.json';
import service from './service.json';
import impact from './impact.json';
import projet from './projet.json';
import contact from './contact.json';

const translation = Object.assign(
  {},
  navbar,
  banner,
  title,
  contexte,
  about,
  service,
  impact,
  projet,
  contact,
);
export default translation;
